
import { Vue, Component } from 'vue-property-decorator'
import { apiSettingsPlatformGetBaiDuAiChargeConfig, apiSettingsPlatformSetBaiDuAiChargeConfig } from '@/api/setting'
@Component({
    components: {}
})
export default class SettingOrder extends Vue {
    /** S Data **/
    form = {}
    list: any = {
        proportion: '',
        body_seg: '',
        selfie_anime: '',
        ai_img: '',
        ai_timg: ''
    }
    // 表单验证
    rules: object = {
        proportion: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        body_seg: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        selfie_anime: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        ai_img: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        ai_timg: [{ required: true, message: '必填项不能为空', trigger: 'blur' }]
    }
    /** E Data **/

    // 获取wms设置
    getSettingOrder() {
        apiSettingsPlatformGetBaiDuAiChargeConfig().then(res => {
            this.list = res;
        })
    }

    // 修改wms设置
    setSettingOrder() {
        const refs = this.$refs.form as HTMLFormElement
        refs.validate((valid: boolean) => {
            if (valid) {
                apiSettingsPlatformSetBaiDuAiChargeConfig(this.list).then(() => {

                })
            }
        })
    }

    /** S Life Cycle **/
    created() {
        this.getSettingOrder()
    }
    /** E Life Cycle **/
}
